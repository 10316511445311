let Global = (function(global) {

  if (global) {
    if (!global.clearoutphone) {
      global.clearoutphone = []
    }
  } 
  
  return {
    set: (key, obj) => {
      global.clearoutphone[key] = obj
    },
  
    get: key => global.clearoutphone[key]
  }
})(window)
  
export default Global