import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import { userReducer } from './components/user'
import appReducer from './appReducer'

export default combineReducers({
  routing: routerReducer,
  appReducer,
  userReducer
})