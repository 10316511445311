import appEventEmitter, {AppEvents} from './AppEventEmitter'

const MatomoTracker = {

  _registerAllEvents: function() {
    // Login Success
    appEventEmitter.addListener(AppEvents.user_login_success, (userInfo) => {
      this.setUserId(userInfo)
    })

    let appEventListeners = []
    for(let eventProp in AppEvents) {
      if (AppEvents.hasOwnProperty(eventProp)) {
        let eventVal = AppEvents[eventProp]
        let listener = appEventEmitter.addListener(eventVal, (props) => {
          // console.log(`matomo tracking event ${eventVal}`)
          window._paq.push(['trackEvent', eventVal, JSON.stringify(props)])
        })
        appEventListeners.push(listener)
      }
    }

    appEventEmitter.addListener(AppEvents.username_update_success, (user) => {
      window._paq.push(['setCustomVariable',2,"Name",user.name,"visit"])
    })

    appEventEmitter.addListener(AppEvents.user_clicked_logout_btn, () => {
      console.info('Reseting matomo..')
      window._paq.push(['resetUserId']);
      window._paq.push(['trackPageView']);
    })
  },

  _unRegisterAllEvents: function() {
    // todo
  },

  getMatomoVisitorId: function() {
    let visitorId
    window._paq.push([ function() { 
      visitorId = this.getVisitorId()
    }
    ])
    return visitorId
  },

  setUserId : function(user) {
    if(user.email){
      window._paq.push(['setUserId', user.id])
      window._paq.push(['trackPageView'])

      window._paq.push(['setCustomVariable',1,"Email",user.email,"visit"])
      window._paq.push(['setCustomVariable',2,"Name",user.name,"visit"])
      window._paq.push(['setCustomVariable',3,"User Id",user.user_id,"visit"])
    }
  },

  init: function() {
    this._registerAllEvents()
  }
}

export default MatomoTracker
