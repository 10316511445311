import { EventEmitter as FbEventEmitter } from 'fbemitter'

export const AppEvents = {
  dashboard_page_loaded: 'Dashboard Page loaded',
  user_login_success: 'User login success',
  user_login_failed: 'User login failed',
  user_clicked_logout_btn: 'User clicked logout button',
  login_page_loaded: 'Login Page loaded',
  signup_page_loaded: 'Sign up page loaded',
  signup_button_clicked: 'Sign up button click',
  signup_success: 'Sign up successful',
  signup_failed: 'Sign up failed',
  organization_signup_page_loaded: 'Organization Sign up page loaded',
  organization_signup_button_clicked: 'Organization Sign up button click',
  organization_signup_success: 'Organization Sign up successful',
  organization_signup_failed: 'Organization Sign up failed',
  bulk_upload_file_added: 'File for bulk upload added',
  user_chunk_bulk_email_verify_upload_started: 'User chunk bulk email verify upload started',
  user_chunk_bulk_email_verify_upload_success: 'User chunk bulk email verify upload success',
  user_chunk_bulk_email_verify_upload_failed: 'User chunk bulk email verify upload failed',
  preset_click_on_overview: 'User clicked on preset in overview page',
  user_clicked_on_buy_credits: 'User clicked on buy credits',
  user_credits_buy_failed: 'User buy credits failed',
  user_buy_credits_success: 'User purchased credits successfully',
  user_individual_profile_showed: 'User individual profile showed',
  quick_validation_results_displayed: 'Quick validation results displayed',
  quick_validation_response_recieved: 'Quick validation response recieved',
  public_quick_validation_response_recieved: 'Public Quick validation response recieved',
  quick_validate_showed_error:'Quick Validate Showed error',
  public_quick_validate_showed_error:'Public Quick Validate Showed error',

  dashboard_page_loaded_with_overview:'Dashboard page loaded with overview',
  dashboard_page_loaded_with_quickvalidate:'Dashboard page loaded with quickvalidate',
  dashboard_page_loaded_with_api:'Dashboard page loaded with api page',
  dashboard_page_loaded_with_billing:'Dashboard page loaded with billing page',
  dashboard_page_loaded_with_profile:'Dashboard page loaded with profile page',
  
  user_switched_to_overview_page:'User switched to overview page',
  user_switched_to_quickvalidate_page:'User switched to quickvalidate page',
  user_switched_to_api_page:'User switched to api page',
  user_switched_to_billing_page:'User switched to billing page',
  user_switched_to_profile_page:'User switched to profile page',

  quick_validation_validate_button_clicked: 'Quick validation validate button clicked',
  quick_validation_export_button_clicked: 'Quick validation export button clicked',
  user_individual_profile_showed_error: 'User individual profile showed error',
  public_user_individual_profile_showed: 'Public instant verify result displayed',
  public_user_individual_profile_showed_error: 'Public instant verify result error',
  user_downloaded_input_file: 'User downloaded input file',
  user_downloaded_result_file: 'User downloaded result file',
  user_downloaded_input_file_errored: 'user input file download erred',
  user_downloaded_result_file_errored: 'user result file download erred',
  user_clicked_on_file_verification_progress: 'User  clicked on file verification progress',
  public_instant_verify_button_clicked: 'public instant verify button  clicked.',
  user_clicked_on_public_instant_verify_button: 'User clicked on instant verify button',
  user_download_result_file_with_more_credits_required: 'User clicked on download result file with credits required',
  user_clicked_on_generate_api_token: 'User clicked on generate api token button',
  user_generated_api_token_successfully: 'User generated api token successfully',
  user_generated_api_token_failure: 'Api token generation for user failed',
  buy_credit_modal_open: 'Buy credit pop up opened',
  pricing_page_loaded: 'Pricing page loaded',
  coupon_page_loaded: 'Coupon page loaded',
  billing_page_loadded: 'Billing page loaded',

  user_clicked_on_edit_name: 'User clicked on edit name',
  username_update_success: 'User name edit successful',
  username_update_error: 'User name edit erred',
  user_clicked_on_edit_email: 'User clicked on edit email',
  user_email_update_success: 'User email edit successful',
  user_email_update_error: 'User name email erred',
  user_clicked_on_edit_timezone: 'User clicked on edit timezone',
  user_timezone_update_success: 'User timezone edit successful',
  user_timezone_update_error: 'User timezone edit erred',
  user_clicked_on_calculate_price: 'User clicked on Calculate price',
  user_clicked_on_calculate_price_error: 'User clicked on calculate price erred',
  user_clicked_on_signout: 'User clicked on sign out',
  user_signout_success: 'User signed out successfully',
  user_clicked_on_change_password: 'User clicked on change password',
  user_password_update_success: 'User password edit successful',
  user_password_update_error: 'User password edit erred',

  //Payment Related Events
  add_card_clicked: 'Add card clicked',
  change_default_card_success: 'Change default card success',
  change_default_card_failed: 'Change default card failed',
  delete_card_click: 'Delete card button click',
  delete_card_success: 'Delete card success',
  delete_card_failed: 'Delete card failed',
  add_card_success: 'Add card success',
  add_card_failed: 'Add card failed',
  invoice_download_clicked: 'Invoice download clicked',
  user_payment_success: 'User paid through card successfully',
  user_payment_failed: 'User payment failed',
  user_clicked_paypal_button: 'User clicked on paypal button',
  user_closed_paypal_window: 'User closed paypal window',
  user_clicked_apply_coupon: 'User clicked on apply coupon',
  coupon_apply_via_url_query_string: 'coupon apply from URL query string',
  apply_coupon_api_erred: 'Apply coupon api erred',
  apply_coupon_success: 'User coupon applied successfully',

  overview_page_loaded: 'Overview Page loaded',
  quick_validation_page_loaded: 'Quick Validation page loaded',
  public_quick_validation_page_loaded: 'Public Quick Validation page loaded',
  list_page_loaded: 'My List page loaded',
  jobs_page_loaded: 'My Jobs page loaded',
  profile_page_loaded: 'Profile page loaded',
  billing_page_loaded: 'Billing page loaded',
  api_page_loaded: 'Api page loaded',
  add_list_page_loaded: 'Add List page loaded',

  //leads
  user_clicked_on_leadfinder_menu: 'User clicked on Leads finder menu',
  user_clicked_on_leads_search: 'User clicked on Leads search',
  user_clicked_on_create_leads_list: 'User clicked on create Leads list  button',
  leads_list_created_successfully: 'Leads list created successfully',
  leads_list_creation_failed: 'Leads list creation failed',
  leads_search_successful: 'Leads search successful',
  leads_search_failed: 'Leads search failed',
  no_leads_found: 'No leads found',
  user_clicked_on_quick_verify: 'User clicked on quick verify button',
  user_clicked_on_public_quick_verify: 'User clicked on Public quick verify button',
  leads_page_loaded: 'Leads page loaded',

  //list info
  list_info_page_loaded: 'My List page loaded',
  user_clicked_refresh: 'User clicked on refresh on my list page',
  user_clicked_verify: 'User clicked on verify on my list page',
  user_clicked_cancel: 'User clicked on cancel action on my list page',
  user_clicked_on_download_submit: 'User clicked on Download submit button',
  user_clicked_on_download_without_append: 'User clicked on Download result file without append',
  user_clicked_on_export_submit: 'User clicked on Export submit button',
  export_successful: 'Export successful',
  export_failed: 'Export failed',

  //integrations
  integrations_page_loaded: 'Integrations page',
  user_clicked_on_view_accounts: 'user clicked on view mailchimp accounts button',
  user_clicked_on_add_more_accounts: 'user clicked on add more mailchimp accounts button',
  user_clicked_on_sync_accounts: 'USer clicked on Sync accounts',
  mailchimp_account_sync_successful: 'Mailchimp accounts sync successful',
  mailchimp_account_sync_failed: 'Mailchimp accounts sync failed',
  user_clicked_on_mailchimp_signup: 'User clicked on mailchimp signup button',

  user_clicked_close_button_on_unauthorized_modal: 'User clicked close button on unauthorized(401) modal window',
  user_clicked_on_redeem_button: 'User clicked on redeem button',
  user_redeemed_credits_successfully: 'User redeemed Credits successfully',
  user_redeem_credits_failed: 'User redeem Credits failed',
  user_clicked_on_integrations: 'User clicked on integrations coming soon button',

  user_selected_on_email_verify_notification: 'User enabled email notification for verify completion',
  user_deselected_email_verify_notification: 'User disabled email notification for verify completion',
  user_clicked_on_email_export_notification: 'User enabled email notification for export completion',
  user_deselected_on_email_export_notification: 'User disabled email notification for export completion',
  user_selected_list_result_expiry: 'User enabled email notification for list results about to expire',
  user_deselected_list_result_expiry: 'User disabled email notification for list results about to expire',

  user_clicked_on_remove_file: 'User clicked on delete input file button',
  user_input_file_deleted_successfully: 'User deleted input file successfully',
  user_input_file_deletion_erred: 'User input file deletion erreed',

  //active campaign
  user_clicked_on_add_active_campaign_account: 'User clicked on add ActiveCampaign account button',
  active_campaign_account_added_successfully: 'ActiveCampaign account added successfully',
  adding_active_campaign_account_failed: 'Adding ActiveCampaign account failed',
  user_clicked_on_view_active_campaign_accounts: 'user clicked on view ActiveCampaign accounts button',

  //moosend
  user_clicked_on_add_moosend_account: 'User clicked on add moosend account button',
  moosend_account_added_successfully: 'Moosend account added successfully',
  adding_moosend_account_failed: 'Adding Moosend account failed',
  user_clicked_on_view_moosend_accounts: 'user clicked on view Moosend accounts button',

  //hubspot
  user_clicked_on_add_hubspot_account: 'User clicked on add Hubspot account button',
  hubspot_account_added_successfully: 'Hubspot account added successfully',
  adding_hubspot_account_failed: 'Adding Hubspot account failed',
  user_clicked_on_view_hubspot_accounts: 'user clicked on view Hubspot accounts button',

  //admin
  user_changed_dashboard_stats_for_individual: 'User changed dashboard stats for individual',
  user_changed_dashboard_stats_for_organization: 'User changed dashboard stats for organization',
  user_clicked_on_admin_tab: 'User clicked on admin tab',
  user_clicked_on_add_member: 'User clicked on add member',
  user_clicked_on_edit_member: 'User clicked on edit member',
  user_clicked_on_member_list: 'User clicked on member list',
  user_clicked_on_show_member_stats: 'User clicked on show member stats',
  user_clicked_on_show_member_teams: 'User clicked on show member teams',
  user_searched_on_member_list: 'User searched on member list',
  user_changed_role_filter: 'User changed role filter',
  user_clicked_on_add_team: 'User clicked on add team',
  user_clicked_on_create_team: 'User clicked on create team',
  user_clicked_on_edit_team: 'User clicked on edit team',
  user_clicked_on_show_all_member_list: 'User clicked on show all member list',
  user_clicked_on_show_team_members: 'User clicked on show team members',
  user_clicked_on_show_team_member_list: 'User clicked on show team member list',
  user_clicked_on_show_team_stats: 'User clicked on show team stats',
  user_clicked_on_add_member_to_team: 'User clicked on add member to team',
  user_clicked_on_remove_member_from_team: 'User clicked on remove member from team',
  user_succesfully_updated_member: 'User Successfully updated member details',
  user_update_member_failed: 'User member update failed',
  user_succesfully_created_member: 'User Successfully created member',
  user_create_member_failed: 'User member create failed',
  user_create_team_failed: 'User team create failed',
  user_update_team_failed: 'User team update failed',
  user_successfully_created_the_team: 'User Successfully created team',
  user_successfully_updated_the_team: 'User Successfully updated team',


  //subscription events
  subscription_tab_loaded: 'Pricing subscription tab loaded',
  user_selected_subscription_plan: 'Subs plan clicked',
  default_selected_plan: 'Default selected plan',
  user_clicked_subscribe_btn_modal: 'User clicked on subscribe button to open modal',
  user_clicked_paypal_subscription: 'User clicked on paypal subscribe button',
  user_paypal_subscribe_create_failed: 'paypal create subscription api failed',
  user_paypal_subscribe_approve_failed: 'paypal approve subscription api failed',
  user_closed_paypal_subscribe_window: 'User closed paypal payment window',
  user_cancelled_current_plan: 'User cancelled current plan',
  user_cancelled_current_plan_failed: 'User cancel plan api failed',
  user_clicked_cancel_on_profile: 'User clicked cancel subscription on profile',
  user_clicked_monthly_tab: 'User clicked monthly pricing tab',
  user_clicked_annual_tab: 'User clicked annual pricing tab',
  
  
  user_clicked_pricing_upgrade : 'User clicked on pricing upgrade button on header'
  
}

class AppEventEmitter extends FbEventEmitter {
  constructor(args) {
    super(args)
  }
  // __emitToSubscription(subscription, eventType) {
  // //   var args = Array.prototype.slice.call(arguments, 2);
  // //   var start = Date.now();
  // //   subscription.listener.apply(subscription.context, args);
  // //   var time = Date.now() - start;
  // //   console.log('callback-time', {eventType, time});
  //   console.log(`event ${eventType} callback called`)
  // }
  // emit(eventType, args) {
  //   super.emit(eventType, args)
  //   console.log(`event ${eventType} emitted`)
  // }
}

export default new AppEventEmitter()
