import React from 'react'
import ReactDOM from 'react-dom'
import Loadable from 'react-loadable'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { Provider } from 'react-redux'
import deepExtend from 'deep-extend'
import TagManager from 'react-gtm-module'

import GoogleAnalyticsTracker from './helpers/googleAnalyticsTracker'
import DevTools from './helpers/DevTools'
import reducer from './reducer'

import MatomoTracker from './helpers/matomoTracker'

// eslint-disable-next-line
import Bootstrap from 'bootstrap/dist/css/bootstrap.css'


import './static/css/clearout.css'
import './static/css/clearout-phone.css'
import './static/css/clearoutphone-theme.css'
import './static/css/font-awesome.css'

import './static/css/ionicons.css'
import './static/css/instant_search.css'

// init Google tag manager
if (GTM_ID) {
  TagManager.initialize({
    gtmId: GTM_ID
  })
}

// init GA4
GoogleAnalyticsTracker.init()

//init Matomo
MatomoTracker.init()

const Dashboard = Loadable({
  loader: () => import(/* webpackChunkName: "Dashboard" */
    './components/dashboard'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Loading...</div>
  }
})

const Login = Loadable({
  loader: () => import(/* webpackChunkName: "Login" */
    './components/cop-login'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Loading...</div>
  }
})

const Register = Loadable({
  loader: () => import(/* webpackChunkName: "Signup" */
    './components/cop-login/register'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Loading...</div>
  }
})

const ListCountries = Loadable({
  loader: () => import(/* webpackChunkName: "Signup" */
    './components/public_quick_validate_phone/display_countries'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Loading...</div>
  }
})

const PublicQuickValidate = Loadable({
  loader: () => import(/* webpackChunkName: "PublicInstantSearch" */
    './components/public_quick_validate_phone/quick_validate_phone'),
  loading: function Loading() {
    return <div id='react-loadable' className='row react-loadable-loading' >Loading...</div>
  }
})

const PublicCredits = Loadable({
  loader: () => import(/* webpackChunkName: "PublicCredits" */
    './components/cophone_dashboard/public_cophone_credits'),
  loading: function Loading() {
    return <div id='react-loadable' className='row react-loadable-loading' >Loading...</div>
  }
})

const SignUp = Loadable({
  loader: () => import(/* webpackChunkName: "SignUpAuthWrapper" */
    './components/cop-login/signup'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Loading...</div>
  }
})

const ResetPassword = Loadable({
  loader: () => import(/* webpackChunkName: "ResetPassword" */
    './components/cop-login/reset_password'),
  loading: function Loading() {
    return <div id='react-loadable' className='row react-loadable-loading' >Loading...</div>
  }
})

const EmailVerified = Loadable({
  loader: () => import(/* webpackChunkName: "EmailVerified" */
    './components/cop-login/email_verified'),
  loading: function Loading() {
    return <div id='react-loadable' className='row react-loadable-loading' >Loading...</div>
  }
})

const ForgotPassword = Loadable({
  loader: () => import(/* webpackChunkName: "SignUpAuthWrapper" */
    './components/cop-login/forgot_password'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Loading...</div>
  }
})

const Download = Loadable({
  loader: () => import(/* webpackChunkName: "Download" */
    './components/download/download'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Downloading...</div>
  }
})

const DownloadWithoutPolling = Loadable({
  loader: () => import(/* webpackChunkName: "DownloadWithoutPolling" */
    './components/download/downloadWithoutPolling'),
  loading: function Loading() {
    return <div id='react-loadable' className='react-loadable-loading' >Downloading...</div>
  }
})

var middlewares = []
middlewares.push(thunkMiddleware)
if (process.env.NODE_ENV !== "production") {
  const loggerMiddleware = createLogger()
  middlewares.push(loggerMiddleware)
}

const enhancer = compose(
  // Middleware you want to use in development: thunkMiddleware - lets us
  // dispatch() functions loggerMiddleware - neat middleware that logs actions
  // routingMiddleware - to capture dispatched actions created by the action
  // creators
  applyMiddleware(...middlewares), DevTools.instrument())

const rootReducer = (state, action) => {
  if (action.type === 'logout-success') {
    state = undefined
  }
  return reducer(state, action)
}

const persistedState = localStorage.getItem(APP_NAME)
  ? JSON.parse(localStorage.getItem(APP_NAME))
  : {}

let initialState = deepExtend(createStore(rootReducer, enhancer).getState(), persistedState)
const store = createStore(rootReducer, initialState, enhancer)

store.subscribe(() => {
  localStorage.setItem(APP_NAME, JSON.stringify(store.getState()))
})

const locationHelper = locationHelperBuilder({})

const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login',
  // Determine if the user is authenticated or not
  authenticatedSelector: state => state.userReducer.isLogged === true,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated'
})

const userIsNotAuthenticated = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the
  // landing page if none is specified and the user is already logged in
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/dashboard',
  // This prevents us from adding the query parameter when we send the user away
  // from the login page
  allowRedirectBack: false,
  // Determine if the user is authenticated or not
  authenticatedSelector: state => state.userReducer.isLogged === false,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsNotAuthenticated'
})

const DashboardAuthWrapper = userIsAuthenticated(Dashboard)
const LoginAuthWrapper = userIsNotAuthenticated(Login)
const SignUpAuthWrapper = userIsNotAuthenticated(SignUp)
const ForgotPasswordWrapper = userIsNotAuthenticated(ForgotPassword)

const App = () => (
  <Switch>
    <Route exact path='/'>
      <Redirect to={"/login" + window.location.search} />
    </Route>
    <Route path='/dashboard' component={DashboardAuthWrapper} />
    <Route path='/login' component={LoginAuthWrapper} />
    <Route path='/register' component={Register} />
    <Route path='/signup' component={SignUpAuthWrapper} />
    <Route path='/forgot_password' component={ForgotPasswordWrapper} />
    <Route path='/public/public_quick_validate' component={PublicQuickValidate} />
    <Route path='/public/countries_list' component={ListCountries} />
    <Route path='/public/credits' component={PublicCredits} />
    <Route path='/resetpassword' component={ResetPassword} />
    <Route path='/emailverification' component={EmailVerified} />
    <Route path='/download_result/:download_id' component={Download} />
    <Route path='/download/result' component={DownloadWithoutPolling} />
  </Switch>
)

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <div>
        <Route path="/" component={App} />
      </div>
    </Router>
  </Provider>,
  document.getElementById('root'));


// serviceWorker.unregister();
