import React from 'react'
import appEventEmitter, { AppEvents } from '../../helpers/AppEventEmitter'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


import global from '../../helpers/global'

const MODELALERT = "modalalert"
const INIT_STATE = {
  show: false,
  title: "Untitled",
  content: "Message has to go here",
  onCloseHandler: null,
  onHideHandler: null
}

class ModalAlertComp extends React.Component {
  constructor(props) {
    super(props)
    this.state = INIT_STATE
    this.isopened = false
  }

  componentDidMount () {
    global.set(MODELALERT, this)
  }

  show({title, content, onCloseHandler, onHideHandler}) {
    if (!this.isopened) {
      this.isopened = true
      this.setState({show:true, title, content, onCloseHandler, onHideHandler},() => {
      })
    }
  }

  hide = () => {
    this.isopened = false
    this.state.onHideHandler && this.state.onHideHandler()
    this.setState(INIT_STATE)
  }

  close = () => {
    appEventEmitter.emit(AppEvents.user_clicked_close_button_on_unauthorized_modal, {app_version: APP_VERSION })
    this.isopened = false
    this.state.onCloseHandler && this.state.onCloseHandler()
    this.setState(INIT_STATE)
  }

  isOpened() {
    return this.isopened 
  }

  render () {
    return (
      <Modal isOpen={this.state.show} className='alert-modal'>
        <ModalHeader>{this.state.title}</ModalHeader>
        <ModalBody>
          {this.state.content}
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-clearout-phone" onClick={this.close}>Ok</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export function ModalAlert() {
  return global.get(MODELALERT)
}

export default ModalAlertComp