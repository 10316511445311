import axios from 'axios'
import moment from 'moment'

import * as Constants from './constants'
import { Notify } from '../components/notifications'
import { Redirect } from '../components/notifications/redirect'
import { versionupdate } from '../components/version_update_modal'

import { ModalAlert } from '../components/notifications/modal_alert'
import { NavHeader } from '../components/dashboard/header'

export const Header = {
  'Content-Type': 'application/json'
}
export const BaseUrl = APP_API_URL

export const getUserIdFromLocalStorage = () => {
  let user = JSON.parse(localStorage.getItem(APP_NAME))
  if (user && user[Constants.CO_USER].id) {
    return user[Constants.CO_USER].id
  } else {
    console.warn('No token found')
    return null
  }
}

export function getRandomId() {
  return Math.floor(Math.random() * 90000) + 10000;
}

export const getAccessToken = () => {
  let user = JSON.parse(localStorage.getItem(APP_NAME))
  if (user && user[Constants.CO_USER].jwtToken) {
    return user[Constants.CO_USER].jwtToken
  } else {
    console.warn('No token found')
    return null
  }
}

export const clearLocalStorage = () => {
  localStorage.removeItem(APP_NAME)
}

export const getAuthHeader = () => {
  let header = Header
  if (getAccessToken()) {
    header = Object.assign({}, Header, {
      Authorization: 'Bearer: ' + getAccessToken(),
      'X-Cop-App-Version': APP_VERSION
    })
  } else {
    header = Object.assign({}, Header, {
      'X-Cop-App-Version': APP_VERSION
    })
  }
  return header
}

export const getMultipartAuthHeader = () => {
  let header
  if (getAccessToken()) {
    header = Object.assign({}, {
      Authorization: 'Bearer: ' + getAccessToken(),
      'X-Cop-App-Version': APP_VERSION
    })
  }
  return header
}

export function ajax(url, method, data = null, appendBaseUrl = true, cancelToken, appendAuth =true) {
  return new Promise((resolve, reject) => {
    let apiUrl = url
    if (appendBaseUrl)
      apiUrl = BaseUrl + url

    const headers = getAuthHeader()

    var options ={}
    if(appendAuth){
      options = {
        url: apiUrl,
        method: method,
        headers
      };
    }else{
      options = {
        url: apiUrl,
        method: method,
        header : Object.assign({}, {
          'Content-Type': 'application/json'
        }, {
          'X-Cop-App-Version': APP_VERSION
        })
      };
    }
    if (data) {
      options.data = data
    }
    if (cancelToken) {
      options.cancelToken = cancelToken
    }
    axios(options).then((res) => {
      resolve(res.data)
    }).catch((err) => {
      let notificationDetails
      if (err.response) {
        switch (err.response.status) {
          case 500:
            //Internal Server Error'
            break

          case 401:
            console.error('Unauthorized')
            ModalAlert().show({
              title: 'Login Required',
              content: "Session expired. Please login again to continue.",
              onCloseHandler: NavHeader().signOutClick,
              onHideHandler: NavHeader().signOutClick
            })
            break

          case 402:
            notificationDetails = {
              title: 'Payment Error',
              message : err.response.data.error && err.response.data.error.additional_info ? ('You have exhausted your credits, please add additional ' + err.response.data.error.additional_info.required_credits + ' credits to continue.') : err.response.data.error.message,
              level: "error",
              uid: 'payment-required-error'
            }
            Notify().showNotification(notificationDetails)
            setTimeout(() => {
              Redirect().redirect('/dashboard/pricing')
            }, 5000)
            break

          case 429:
            console.log('error', err)
            break;

          case 400:
            //Bad request
            break
          case 418:
            versionupdate().openModal(err.response.data)
            break
          default:
            break
        }
      }

      reject(err)
    })
  })
}

export function ajaxHeader(url, method, data = null, appendBaseUrl = true, cancelToken) {
  return new Promise((resolve, reject) => {
    let apiUrl = url
    if (appendBaseUrl)
      apiUrl = BaseUrl + url

    const headers = getAuthHeader()

    var options = {
      url: apiUrl,
      method: method,
      headers
    };
    if (data) {
      options.data = data
    }
    if (cancelToken) {
      options.cancelToken = cancelToken
    }
    axios(options).then((res) => {
      resolve(res)
    }).catch((err) => {
      let notificationDetails
      if (err.response) {
        switch (err.response.status) {
          case 500:
            //Internal Server Error'
            break

          case 401:
            console.error('Unauthorized')
            ModalAlert().show({
              title: 'Login Required',
              content: "Session expired. Please login again to continue.",
              onCloseHandler: NavHeader().signOutClick,
              onHideHandler: NavHeader().signOutClick
            })
            break

          case 402:
            notificationDetails = {
              title: 'Payment Error',
              message : err.response.data.error && err.response.data.error.additional_info ? ('You have exhausted your credits, please add additional ' + err.response.data.error.additional_info.required_credits + ' credits to continue.') : err.response.data.error.message,
              level: "error",
              uid: 'payment-required-error'
            }
            Notify().showNotification(notificationDetails)
            setTimeout(() => {
              Redirect().redirect('/dashboard/pricing')
            }, 5000)
            break

          case 429:
            console.log('error', err)
            break;

          case 400:
            //Bad request
            break
          case 418:
            versionupdate().openModal(err.response.data)
            break
          default:
            break
        }
      }

      reject(err)
    })
  })
}

export function validateEmailAddress(email){
  let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email)
}

export function isNumber(value){
  let regex = /^[0-9]+$/;
  return regex.test(value)
}

export function capitalizeFirstLetter(string) {
  if(string){
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string
}

export function getBrandImage(brand) {
  let image = 'Visa'
  switch (brand) {
    case 'Visa':
      image = require("../static/img/card-brands/visa.png")
      break
    case 'Mastercard':
      image = require("../static/img/card-brands/mastercard.png")
      break
    case 'American Express':
      image = require("../static/img/card-brands/americanexpress.png")
      break
    case 'Diners Club':
      image = require("../static/img/card-brands/dinerclub.png")
      break
    case 'JCB':
      image = require("../static/img/card-brands/jcb.png")
      break
    case 'Discover':
      image = require("../static/img/card-brands/discover.png")
      break
    default:
      image = require("../static/img/card-brands/visa.png")
      break
  }
  return image
}

export function calculateBounceRate(stats) {
  let bounceRate = 0
  let count = stats.valid + stats.invalid
  if(stats.total !== 0) {
    bounceRate = (Number(stats.invalid) / Number(count) * 100)
  }
  return bounceRate > 0 ? bounceRate : 0
}

export function calculateEstimatedCostSaved(count) {
  let costSaved = 0
  costSaved = (Number(count) * Constants.AVG_EMAIL_SENDING_COST)
  return costSaved
}

export function getDeliveryStatusFromDeliverableScore(deliverable_score) {
  let status = {}
  if(deliverable_score === 100) {
    status.name = 'No Risk'
    status.value = 'no_risk'
  } else if(deliverable_score >= 75) {
    status.name = 'Low Risk'
    status.value = 'no_risk'
  } else if(deliverable_score >= 50) {
    status.name = 'Moderate Risk'
    status.value = 'moderate_risk'
  } else {
    status.name = 'High Risk'
    status.value = 'high_risk'
  }
  return status
}

export function pickChartColor(index) {
  if(index > Constants.GRAPH_COLORS.length - 1) {
    index = index % (Constants.GRAPH_COLORS.length - 1)
  }
  let color = Constants.GRAPH_COLORS[index]
  return color
}

export function formatTime(time, format){
  return moment.parseZone(time).format(format)
}

export function calculatePrice(credits) {
  let slabs, slab = { 
      max: 0,
      min: 0,
      unit_price: 0
    },
    actual_amount = 0
  let user = JSON.parse(localStorage.getItem(APP_NAME))
  if (user && user['appReducer'].slabs) {
    slabs = user['appReducer'].slabs
  }
  if(slabs && slabs.length > 0){
    slab = slabs && slabs.find(function (s) {
      if(s.min <= credits && (s.max ? s.max : Infinity) >= credits)
        return s
    })
    actual_amount = credits * slab.unit_price
  }
  return {
    slab: slab,
    credits: credits,
    amount: actual_amount.toFixed(6)
  }
}

export function getResultAsReqFormat(result, metric){
  if(Constants.RESULTS[result].type == 'percentage'){
    if(metric && metric.value !== null){
      let val = Number(metric.value).toLocaleString()
      let percent = metric.value ? ' (' + Number(metric.percentage) + '%)' : ''
      return val + percent
    }else{
      return '-'
    }
  }else{
    return isNumber(metric) ? Number(metric).toLocaleString() : '-'
  }
}

// export function getFileTypeImage(brand) {
//   let image = 'csv'
//   switch (brand) {
//     case 'csv':
//       image = require('../static/svg/csv.svg')
//       break
//     case 'xlsx':
//       image = require('../static/svg/xlsx.svg')
//       break
//     case 'mailchimp':
//       image = require('../static/svg/mailchimp.svg')
//       break
//     case 'leads':
//       image = require('../static/svg/leads.svg')
//       break
//     case 'upload':
//       image = require('../static/svg/upload.svg')
//       break
//     case 'active_campaign':
//       image = require('../static/svg/ac_small_logo.svg')
//       break
//     case 'moosend':
//       image = require('../static/svg/moosend_icon.svg')
//       break
//     case 'hubspot':
//       image = require('../static/svg/hs_small_logo.svg')
//       break
//     default:
//       image = require('../static/svg/csv.svg')
//       break
//   }
//   return image
// }

export function addHttp(url) {
  if (!/^(f|ht)tps?:\/\//i.test(url)) {
    url = "http://" + url;
  }
  return url;
}

export function popupCenter(url, title, w, h, callback ) {
  var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
  var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

  let width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  let height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  var left = ((width / 2) - (w / 2)) + dualScreenLeft;
  var top = ((height / 2) - (h / 2)) + dualScreenTop;
  var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
  var timer = setInterval(checkChild, 500);

  function checkChild() {
    if (newWindow.closed) {
      // Make api call here... on window closed
      if(callback)
        callback()
      
      clearInterval(timer);
    }
  }
  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
}

export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function validateUserName(username){
  let regex = /^[a-zA-Z0-9._\s]*$/;
  return regex.test(username)
}


export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}